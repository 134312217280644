<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/complaint`"
                title="Complaint"
                :columns="columns"
                routerpath="/crm/complaint/addcomplaint"
                :formOptions="formOptions"
                :newPage="true"
                :edit="edit"
                :add="add"
                :canDelete="canDelete"
                :viewFields="viewFields"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "ID",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search ID",
          },
          hidden: true,
        },
        {
          label: "Complaint Date",
          field: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Complaint Date",
          },
        },
        {
          label: "Type",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "Search Type",
          },
        },
        {
          label: "Manager Name",
          field: "managername",
          type: "dropdown",
          url: "team",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Manager Name",
          },
        },
        {
          label: "Customer Name",
          field: "customer",
          filterOptions: {
            enabled: true,
            placeholder: "Search Customer Name",
          },
        },
        {
          label: "Mobile No",
          field: "mobile",
          filterOptions: {
            enabled: true,
            placeholder: "Search Mobile No",
          },
        },
        {
          label: "Address",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Search Address",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",

          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      viewFields:[
        {
          label:'Complaint Date',
          field:'datetime',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Type',
          field:'type',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Manager Name',
          field:'name',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Complaint Category',
          field:'category',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Customer Name',
          field:'customer',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Mobile No',
          field:'mobile',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Address',
          field:'address',
          type:'text',
          class:'col-md-4'
        },
        {
          label:'Remarks',
          field:'remarks',
          type:'text',
          class:'col-md-4'
        },
      ],
      formOptions: {
        formTitle: `${
          this.$route.params.id ? "Edit Complaint" : "Add Complaint"
        }`,
        submitRouterPath: "/crm/complaint",
        gridForm: true,
        method: "post",
        action: "add",
        getEditValue: `${baseApi}/getcomplaintById`,
        url: `${baseApi}/complaint`,
        inputFields: [
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
          {
            label: "Modified Date",
            field: "enterdatetime",
            filterOptions: {
              enabled: true,
              placeholder: "Search Date",
            },
          },
        ],
      },

      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Complaint") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
